<template>
  <div class="transaction">
    <div class="transaction-con">
      <div class="transtion-title">Transactions</div>
      <div class="search-box">
        <a-card>
          <a-form :model="form" layout="vertical" ref="formRef">
            <a-row :gutter="[20, 20]">
              <a-col :span="5">
                <a-form-item field="type" label="Type">
                  <a-select v-model="form.type" multiple :max-tag-count="1">
                    <a-option :value="i + 1" v-for="item, k, i in types" :key="k">{{ item }}</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item field="tracking" label="Tracking">
                  <a-input v-model="form.tracking" placeholder="E.g. 1132299310964" @keydown.enter="search" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="orderid" label="Order ID">
                  <a-input v-model="form.orderid" placeholder="E.g. 202208213849273" @keydown.enter="search" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="transactionid" label="Transaction ID">
                  <a-input v-model="form.transactionid" placeholder="E.g. 20220509182737223666"
                    @keydown.enter="search" />
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item field="createTime" label="Create Time">
                  <a-config-provider :locale="enUS">
                    <a-range-picker v-model="form.createTime" :placeholder="['From', 'To']" :format="dateFormate" />
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="3" class="operate">
                <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset"
                  cancel-text="Cancel" @ok="reset" class="warnPop" style="width:280px;">
                  <a-button shape="round">Reset</a-button>
                </a-popconfirm>
                <a-button type="primary" shape="round" @click="search">Search</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
      <!-- 表格 -->
      <div class="table-data">
        <a-config-provider :locale="enUS">
          <a-table :columns="listColumns" :data="listData.value" :pagination="false" :bordered="false"
            :scroll="{ y: tableHeight.value }" :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 30 + 'px' }" class="empty-cont">
                <img src="../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 30 + 'px' }" class="skeleton-box">
              </div>
            </template>
            <template #transaction_id="{ record }">
              <span>{{ record.transaction_id }}</span>
              <a-tooltip content="Copy">
                <icon-copy class="copy" @click="copyVal(record.transaction_id)" />
              </a-tooltip>
            </template>
            <template #amount="{ record }">
              ${{ toThousands(record.amount) }}
            </template>
            <template #type="{ record }">
              <a-tag v-if="record.type_desc === 'ACH Charge' || record.type_desc === 'Admin Charge'" color="green">{{
    record.type_desc
}}</a-tag>
              <a-tag v-else color="red">{{ record.type_desc }}</a-tag>
            </template>
            <template #balance="{ record }">
              ${{ toThousands(record.balance) }}
            </template>
            <template #orderid="{ record }">
              <div v-if="record.order_id">
                <span class="order-id " @click="goOrderDetail(record.order_id)">{{
    record.unique_id
}}
                </span>
                <a-tooltip content="Copy">
                  <icon-copy class="copy" @click="copyVal(record.unique_id)" />
                </a-tooltip>
              </div>
              <div v-else>N/A</div>
            </template>
          </a-table>
        </a-config-provider>
      </div>
      <div class="page">
        <a-space>
          <span>Total : {{ totalNum }} items</span>
          <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
            <icon-left />
          </a-button>
          <span class="currentpage">{{ currentpage }}</span>
          <div>/</div>
          <div>{{ totalPages }}</div>
          <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
            <icon-right />
          </a-button>
          <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
            <a-option :value="20">20 / Page</a-option>
            <a-option :value="50">50 / Page</a-option>
            <a-option :value="100">100 / Page</a-option>
            <a-option :value="200">200 / Page</a-option>
          </a-select>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeRouteLeave, useRouter, useRoute } from 'vue-router'
import { ref, reactive, onMounted, onBeforeMount } from 'vue';
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { transaction } from '@/api/account.js'
import { toThousands } from '@/utils/handleNumber.js'
import local from '@/utils/local.js'
import { Message } from '@arco-design/web-vue'
import {
  IconRight, IconLeft, IconCopy
} from '@arco-design/web-vue/es/icon'
const loading = ref(false)
const router = useRouter()
const route = useRoute()
onMounted(() => {
  // 修改全局滚动条
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:hidden;
  `
})
onBeforeRouteLeave(() => {
  // 离开滚动条该回去
  const mainCont = document.querySelector('.main-cont')
  mainCont.style = `
   overflow-y:auto;
  `
})

// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
tableHeight.value = document.body.clientHeight - 408
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableHeight.value = document.body.clientHeight - 408
  }
})
const listColumns = [
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    slotName: 'transaction_id',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 205
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.id > b.id) {
          sortVal = 1
        } else if (a.id < b.id) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    title: 'Order ID',
    dataIndex: 'unique_id',
    slotName: 'orderid',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    title: 'Type',
    sortable: { sortDirections: ['ascend', 'descend'] },
    dataIndex: 'type_desc',
    slotName: 'type',
    width: 180
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    slotName: 'amount',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.amount) > parseFloat(b.amount)) {
          sortVal = 1;
        } else if (parseFloat(a.amount) < parseFloat(b.amount)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    width: 125
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    slotName: 'balance',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.balance) > parseFloat(b.balance)) {
          sortVal = 1;
        } else if (parseFloat(a.balance) < parseFloat(b.balance)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    width: 125
  }
]
const formRef = ref(null)
const types = JSON.parse(local.get('configInfo')).transaction_type
const form = reactive({
  type: [1, 2, 3, 4, 5, 6, 7, 8],
  tracking: '',
  orderid: '',
  transactionid: '',
  createTime: ''
})
let track = ref(route.query.track)
if (track.value) {
  // console.log(track.value)
  form.tracking = track.value
  form.type = [8]
}
let type = ref(route.query.type)
const startTime = ref(route.query.start)
const endTime = ref(route.query.end)
if (type.value || startTime.value || endTime.value) {
  form.type = [Number(type.value)]
  form.createTime = [startTime.value, endTime.value]
}
// 重置搜索
const reset = () => {
  // formRef.value.resetFields()
  form.type = [1, 2, 3, 4, 5, 6, 7, 8]
  form.tracking = ''
  form.orderid = ''
  form.transactionid = ''
  form.createTime = ''
  currentpage.value = 1
  getList()
}
// 表格数据
let listData = reactive({ value: [] })
// 初次渲染表格
onBeforeMount(() => {
  getList()
})

// 当前页
const currentpage = ref(1)
// 总页数
const totalPages = ref(0)
// 总数据
const totalNum = ref(0)
const search = () => {
  currentpage.value = 1
  getList()
}
const pageSize = ref(20)
// 获取地址列表
const getList = async () => {
  loading.value = true
  const msg = await transaction({
    page: currentpage.value,
    tracking: form.tracking,
    order_id: form.orderid,
    createTime: form.createTime,
    transaction_type: form.type,
    transaction_id: form.transactionid,
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    currentpage.value = msg.data.current_page
    totalPages.value = msg.data.page_count
    totalNum.value = msg.data.total
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push(info[i])
    }
    listData.value = currentData
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}

// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getList()
}
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
// 跳转order详情
const goOrderDetail = (id) => {
  router.push('/order/list/listdetail?orderId=' + id)
}
const selectPage = (val) => {
  pageSize.value = val
  getList()
}
// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
// console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}
</script>

<style lang="less" scoped>
.operate {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 82px;
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.transtion-title {
  font-weight: 500;
  font-size: 20px;
  padding: 18px 24px 18px 48px;
  color: var(--color-text-1);
}

.search-box {
  margin: 0 24px;
}

.table-data {
  margin: 24px;
}

.order-id {
  font-weight: 400;
  font-size: 14px;
  color: rgb(var(--primary-6));
  cursor: pointer;
}

.copy {
  color: var(--color-fill-4);
  padding-left: 4px;
}

.page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 100px;
  margin-right: 20px;
  margin-top: 24px;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
